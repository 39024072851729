.badge-block {
  position: fixed;
  bottom: 45px;
  right: 25px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #242a31;
  box-shadow: 0px 0px 2px 5px rgba(0, 0, 0, 0.26);
  -webkit-box-shadow: 0px 0px 2px 5px rgba(0, 0, 0, 0.26);
  -moz-box-shadow: 0px 0px 2px 5px rgba(0, 0, 0, 0.26);
  line-height: 35px;
  text-align: center;
  cursor: pointer;
  z-index: 100;
  opacity: 0;
  transform: translateY(100px);
  transition: all 0.5s ease;
}

.badge-block.show {
  opacity: 1;
  transform: translateY(0);
}

.badge-block .count {
  position: absolute;
  top: -5px;
  right: -5px;
  border-radius: 50%;
  background: red;
  color: white;
  font-size: 12px;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
}

.badge-block .arrow {
  display: inline-block;
  transform: rotate(90deg);
  color: white;
  width: 15px;
}
