.message {
  margin: 5px 0;
  max-width: calc(80% + 40px);
  display: flex;
  justify-content: left;
  align-items: center;
}

.message > span {
  display: inline-block;
  padding: 8px;
  border-radius: 10px;
  color: white;
  position: relative;
  min-width: 80px;
  text-align: left;
  word-wrap: break-word;
}

.message p {
  margin: 0;
  line-height: 1.5;
}

.message p::after {
  content: "";
  display: inline-block;
}

.right-align p::after {
  margin-right: 60px;
}

.left-align p::after {
  margin-right: 40px;
}

.message .bottom-right {
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.right-align {
  text-align: right;
  margin-left: auto;
  margin-right: 0;
  justify-content: right;
  flex-direction: row-reverse;
}

.right-align > span {
  background-color: #242a31;
  border-bottom-right-radius: 0;
  margin-left: 10px;
}

.left-align {
  text-align: left;
  margin-left: 0;
  margin-right: auto;
}

.left-align > span {
  background-color: #53a6bd;
  border-bottom-left-radius: 0;
  margin-right: 10px;
}

.message-list-container {
  overflow-y: overlay;
  max-height: calc(100% - 38px);
  min-height: calc(100% - 38px);
  padding: 0 30px;
}

.created-at {
  margin: 0 5px;
  font-size: 10px;
  color: #dedede;
}

.unread-messages {
  text-align: center;
  margin: 5px 0;
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  padding: 2px;
}

.unread-messages span {
  background: #242a31;
  color: #cacaca;
  padding: 5px 10px;
  border-radius: 10px;
  display: inline-block;
}

.date-time {
  text-align: center;
  position: sticky;
  top: 10px;
  z-index: 1;
}

.date-time span {
  font-size: 11px;
  padding: 5px;
  background: #72767a;
  border-radius: 10px;
  color: white;
  display: inline-block;
  min-width: 95px;
}

.hover-container {
  cursor: pointer;
  position: relative;
  height: 20px;

  line-height: calc(10px + 5px / 2);
}

.hover-container img:not(.visible) {
  visibility: hidden;
}

.message:hover .hover-container img {
  /* display: inline-block; */
  visibility: visible;
}

.italic-font {
  font-style: italic;
  font-weight: lighter;
}

.edited-message {
  font-size: 10px;
  margin-left: 5px;
  color: #b9b9b9;
}
