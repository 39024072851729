.welcome-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100vw;
  /* background: #53a6bd; */
  color: white;
  background: rgb(83, 166, 189);
  background: linear-gradient(
    307deg,
    rgba(83, 166, 189, 1) 0%,
    rgba(83, 166, 189, 0.5130427170868348) 15%,
    rgba(153, 153, 153, 1) 100%
  );
}

.welcome-block {
  position: relative;
  background: #242a31;
  padding: 30px;
  border-radius: 5px;
  margin: auto 20px;
  max-width: 400px;
}

.welcome-block input {
  display: block;
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  background: #585858;
  color: #aadbe8;
  border: none;
  /* padding-left: 30px; */
  border-radius: 5px;
  outline: none;
}

.welcome-block input::placeholder {
  color: #aadbe8;
}

.welcome-block .input-field-cover {
  position: relative;
}

/* .welcome-block .input-field-cover::before {
  content: "";
  background: url("../../assets/images/user.png");
  height: 15px;
  width: 15px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  left: 6px;
  top: 50%;
  transform: translateY(-50%);
} */

.welcome-block button {
  width: 100%;
  padding: 8px 10px;
  background-color: #53a6bd;
  border: none;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 12px;
  cursor: pointer;
}

.welcome-block p {
  margin-top: 0;
}

.error-message {
  font-size: 10px;
  color: red;
}

.connect-icon {
  position: absolute;
  height: 70px;
  width: 70px;
  top: calc(-70px - 10px);
  left: 50%;
  transform: translateX(-50%);
}

.welcome {
  margin-bottom: 5px;
}

.policy {
  font-size: 10px;
}

.switch_form {
  margin-top: 15px !important;
  margin-bottom: 0;
}

.switch_form button {
  margin: 0;
  background: no-repeat;
  padding: 0;
  width: fit-content;
  color: #53a6bd;
  cursor: pointer;
}

.password-field {
  position: relative;
}

.password-field .eye-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  height: 32px;
}

.password-field .eye-cross {
  display: none;
  height: 70%;
  width: 1px;
  background: black;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
}

.password-field input[type="text"] + .eye-icon .eye-cross {
  display: block;
}
