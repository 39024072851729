.notifications-container {
  height: calc(100% - 40px);
  overflow-y: auto;
}

.notification-card {
  padding-bottom: 5px;
  border-bottom: 1px solid #afaaaa;
  margin-bottom: 10px;
}

.notification-card .notification-text {
  margin-bottom: 5px;
}

.notification-card .button {
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  color: white;
  margin-right: 10px;
  cursor: pointer;
}

.notification-card .accept {
  background-color: #69c369;
}

.notification-card .reject {
  background-color: #c94949;
}

.notification-title {
  border-bottom: 1px solid #afaaaa;
  padding: 5px 0;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}
