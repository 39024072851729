.loader-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(1, 1, 1, 0.5);
}

.loader {
  height: 40px;
  width: 40px;
  display: inline-block;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  /* transform: translate(-50%, -50%); */
  border: 5px solid #daffda;
  border-top: 5px solid #00ff4e;
  border-radius: 50%;
  animation: spin 1.1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
