.field {
  position: relative;
  margin-bottom: 15px;
}

.field label {
  display: inline-block;
  margin-bottom: 5px;
}

.field input {
  margin-bottom: 5px;
}

.field span.error {
  font-size: 12px;
  color: red;
}
