.search-container {
  height: calc(100% - 34px);
}

.bell-icon-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.bell-icon-container span.notification-count {
  position: absolute;
  top: -10px;
  left: calc(100% - 10px);
}

.search-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 24px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ada9a9;
  margin-bottom: 10px;
}

.search-actions.search {
  /* width: calc(100% - 20px); */
  padding: 5px;
}

.refresh-connections {
  cursor: pointer;
}

input.search {
  height: 30px;
  width: calc(100% - 30px);
}
