.sidemenu-container {
  position: absolute;
  left: 0;
  height: 100%;
  width: 18em;
  border-right: 1px solid gray;
  box-sizing: border-box;
  transition: 0.3s ease-in;
  padding: 0 10px;
  background: #242a31;
  color: white;
}

.sidemenu-container.hide {
  left: -18em;
}

.user-name {
  margin: 5px;
  font-size: 20px;
  height: 24px;
}

@media screen and (max-width: 659px) {
  .sidemenu-container {
    z-index: 10;
    max-width: 80%;
  }
}
