.notifiction-circle:before,
.notifiction-circle:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 50%;
  border: 1px solid red;
}

.notifiction-circle:before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}
.notifiction-circle:after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(1);
  }
  75% {
    -webkit-transform: scale(1.75);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(2);
    opacity: 0;
  }
}

@keyframes ripple {
  0% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.75);
    opacity: 1;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
