.searchlist-container {
  height: calc(100% - 40px);
  overflow: auto;
}

.searchlist-container .name {
  margin: 5px 0;
}

.searchlist-container .connect-button {
  border: none;
  padding: 10px 15px;
  background-color: #47da7b;
  border-radius: 5px;
  color: white;
  cursor: pointer;
}

.searchlist-container .search-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}
