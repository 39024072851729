.main-container {
  position: relative;
  margin-left: 18em;
  transition: 0.3s ease-in;
  height: 100%;
  background: rgb(83, 166, 189);
  background: linear-gradient(
    307deg,
    rgba(83, 166, 189, 1) 0%,
    rgba(83, 166, 189, 0.5130427170868348) 15%,
    rgba(153, 153, 153, 1) 100%
  );
  min-height: 100%;
}

.main-container.expand {
  margin-left: 0;
}

.back-drop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  z-index: 9;
  background: rgba(83, 166, 189, 0.8);
}

@media screen and (max-width: 659px) {
  .main-container {
    margin-left: 0;
  }

  .back-drop.show {
    display: block;
  }
}
