.dialog-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 12;
}

.dialog-container .dialog-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background: #242a31;
  border-radius: 10px;
}

.dialog-container .dialog-content p {
  margin-top: 0;
  color: white;
}

.dialog-container .dialog-content form {
  display: flex;
  justify-content: space-evenly;
}

.dialog-container .dialog-content form button {
  padding: 10px;
  color: white;
  border-radius: 5px;
  border: none;
  width: 80px;
  cursor: pointer;
}

.dialog-container .dialog-content form button.cancel {
  background: #69c369;
}

.dialog-container .dialog-content form button.submit {
  background: #c94949;
}
