.chatroom-container {
  position: relative;
  padding-top: 46px;

  /* background: #edeff3; */
  background: transparent;
  z-index: 1;
}

.chatroom-container.chat-screen {
  height: calc(100% - 46px);
}

.chatroom-container.expand {
  width: 100%;
  margin-left: 0;
}

.chatroom-container form {
  height: 100%;
  position: relative;
}

.chatroom-container .chat-input {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.chatroom-container .chat-input input:not(.send-message) {
  box-sizing: border-box;
  width: calc(100% - 55px);
  padding: 5px;
  margin: 5px 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.chatroom-container .send-message {
  height: 25px;
  width: 25px;
  position: absolute;
  right: 2px;
  bottom: -2px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
  transform: scale(0.6, 0.6);
  background-color: #242a31;
}

.chatroom-container .send-message.disable {
  background-color: #545454;
}

.chatroom-container .edit-message-actions {
  position: absolute;
  right: 50px;
  bottom: 10px;
  display: none;
}

.chatroom-container .edit-message-actions.show {
  display: block;
}

.chatroom-container .edit-message-actions button {
  padding: 0;
  border: 0;
  background: none;
  cursor: pointer;
}

.chatroom-container .edit-message-actions button:first-child {
  margin-right: 5px;
}

.chatroom-container .edit-message-actions button img {
  height: 20px;
  display: block;
}

.banner-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  height: calc(100% - 46px);
  width: 100%;
}

.banner-container img {
  max-width: 100%;
  max-height: calc(100vh - 60px);
  aspect-ratio: 1;
}

.banner-container .banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 400px;
}

.banner-image {
  display: flex;
  flex-direction: column;
  text-align: end;
}

.banner-image a {
  font-size: 10px;
}

.banner-container .banner-content h2,
.banner-container .banner-content p {
  margin-left: 15px;
  margin-right: 15px;
  color: #242a31;
}

@media screen and (max-width: 650px) {
  .banner-container img {
    max-width: 100%;
    max-height: none;
  }
}
