.notification-container {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 3;
}

.notification-block {
  width: 250px;
  padding: 5px;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 0px 6px 0px rgb(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.75);
  transition: transform 0.6s ease-in-out;
  animation: toast-in-right 0.7s;
  display: flex;
  align-items: center;
  position: relative;
}

.notification-block button {
  position: absolute;
  top: 5px;
  right: 8px;
  background: transparent;
  border: none;
  padding: 0;
  font-weight: bold;
  cursor: pointer;
  color: white;
}

.notification-block.success {
  background-color: #4ba157;
}

.notification-block.error {
  background-color: #c84244;
}

.notification-block.info {
  background-color: #40aac3;
}

.notification-block .icon img {
  height: 32px;
}

.notification-block .content {
  padding-left: 8px;
  padding-right: 15px;
}

.notification-block .content h3 {
  margin: 5px 0;
}

.notification-block .content p {
  margin: 5px 0;
  font-size: 13px;
}

@keyframes toast-in-right {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
