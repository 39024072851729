.header-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 45px;
  display: flex;
  align-items: center;
  /* background: #edeff3; */
  background: transparent;
  box-shadow: 0px 2px 12px 0px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 2px 12px 0px rgb(144 144 144 / 75%);
  -moz-box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.75);
  border: none;
  z-index: 2;
}

.header-container.expand {
  left: 0;
}

.header-container img.side-menu-btn {
  height: 25px;
  width: 25px;
  cursor: pointer;
  margin: 0 10px;
}

.header-container .header-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
