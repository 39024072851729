.connections-container .connection-block {
  position: relative;
  padding: 10px 5px;
  border-bottom: 1px solid #cacaca;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.connections-container .connection-block.selected {
  background-color: #585858;
}

.connections-container .connection-block:hover {
  background-color: #585858;
}

.connections-container .connection-block p {
  margin: 0;
}

.connections-container .connection-block .delete-connection {
  display: none;
  margin-left: 5px;
  cursor: pointer;
}

.connections-container .connection-block:hover .delete-connection {
  display: inline-block;
}
