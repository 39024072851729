.message-actions {
  position: absolute;
  top: 15px;
  border: 1px solid #444444;
  border-radius: 5px;
  width: 130px;
  background-color: #242a31;
  z-index: 10;
}

.message-actions button {
  width: 100%;
  background: transparent;
  color: white;
  padding: 8px 5px;
  margin: 0;
  border: none;
  cursor: pointer;
  font-size: 12px;
}

.message-actions button:hover {
  background: #646464;
}

.right-align .message-actions {
  left: -15px;
}

.message.last-message .message-actions {
  bottom: 15px;
  top: unset;
}
